@import '../sass/variables';

.form {
    width: 100%;
    margin-bottom: -10px;

    &__message {
        margin-top: 10px;
        font-size: 0.95rem;
        line-height: 1.25;
    }

    &__message-error {
        margin: 0 0 5px 20px;
        list-style-type: disc;
        display: list-item;
        color: $error;
    }

    &__message-response {
        color: $file-text-color;
        text-align: center;
        margin: 20px 30px;
    }

    &__title {
        font-size: 1.5rem;
        margin: 10px;
        color: $file-text-color;
    }
    
    &__table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid $file-text-color;
    }
    
    &__row {
        border-bottom: 1px solid $file-text-color;
    }
    
    &__label {
        padding: 10px;
        font-weight: 900;
        text-align: left;
        vertical-align: top;
        width: 30%;
        border-right: 1px solid $file-text-color;
        white-space: nowrap;
        color: $file-text-color;
    }
    
    &__input {
        width: 70%;
    }
    
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
        color: $file-text-color;
        font-family: 'Averia Serif Libre', 'Georgia', 'Times New Roman';
        font-weight: 300;
        padding: 10px;
        width: 100%;
        border: none;
        font-size: 1rem;
        box-sizing: border-box;
        resize: none;
        background-color: transparent;
    
        &:focus-visible {
            outline: none;
        }
    }
    
    textarea {
        height: 100px;
    }

    &__button {
        width: 100%;
        padding: 15px;
        font-family: 'Averia Serif Libre', 'Georgia', 'Times New Roman';
        text-transform: uppercase;
        text-align: right;
        font-size: 1.2rem;
        line-height: 1.6;
        background-color: transparent;
        color: $file-text-color;
        border: none;
        cursor: pointer;
        transition: $transition;
    
        &-icon {
            font-size: 1.75rem;
            line-height: 1;
        }
    
        &:hover {
            background-color: darken($forest, 10%);
        }
    
    }
}

@media only screen and (max-width: 650px) {
    
    .form {
        font-size: 1rem;

        &__title {
            font-size: 1.2rem;
        }

        &__label {
            padding: 7.5px;
        }
    
        input[type="text"],
        input[type="email"],
        input[type="tel"],
        textarea {
            padding: 7.5px;
        }

        &__button {
            padding: 10px;
            font-size: 1.1rem;
            line-height: 1.6;
    
            &-icon {
                font-size: 1.5rem;
                line-height: 1;
            }

        }
    }
}