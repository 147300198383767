@import '../sass/mixins';
@import '../sass/variables';

.process {
    @include section-layout(50px, 50px 20px);
    @include section-title;
    position: relative;
    background-color: $primary-bg-color;

    &__group {
        display: flex;
        gap: 30px;
        overflow: hidden;
        scroll-snap-type: x mandatory;
        margin-top: 30px;
        padding-bottom: 20px;
        transition: $transition;
        scroll-behavior: smooth;
    }

    &__item {
        background: #ffffff;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        flex: 0 0 500px;
        scroll-snap-align: start;
        font-size: 1.1rem;
    }

    &__category {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }

    &__description {
        margin-bottom: 15px;
    }

    &__image-wrapper {
        margin-top: 15px;
    }

    &__image,
    &__video {
        width: 100%;
        height: 275px;
        display: block;
        border-radius: 8px;
        background-color: #00000032;
        object-fit: cover;
    }

    &__image-text {
        font-weight: 300;
        font-size: 0.9rem;
    }

    &__link {
        margin-top: 20px;
        font-size: 1rem;
    }

    &__arrow {
        position: absolute;
        font-size: 2rem;
        background: rgba(0, 0, 0, 0.3);
        color: white;
        border: none;
        padding: 20px 35px;
        cursor: pointer;
        z-index: 10;
        border-radius: 100%;
        top: 45%;
    }

    &__arrow--left {
        left: 5vw;
    }

    &__arrow--right {
        right: 5vw;
    }
}

@media (max-width: 1200px) {
    .process {
        &__arrow--left {
            left: 2vw;
        }
    
        &__arrow--right {
            right: 2vw;
        }
    }
}


@media (max-width: 650px) {
    .process {
        &__group {
            flex-direction: column;
            width: 100%;
        }


        &__item {
            flex: 1;
            margin-right: 0px;
            font-size: 1rem;
        }

        &__arrow {
            display: none;
        }
    }
}