@import '../sass/mixins';
@import '../sass/variables';

.contact {
    @include page-layout(60px);

    &__group {
        @include grid(20px, 2);
    }

    &__description {
        margin-right: 30%;
    }
}

.form__wrapper {
    background-color: $forest;
    padding: 20px;
}

@media only screen and (max-width: 1000px) {
    .contact {
        &__group {
            grid-template-columns: 1fr 1.5fr;
        }

        &__description {
            margin-right: 0px;
        }
    }
}

@media only screen and (max-width: 650px) {
    .contact {
        &__group {
            grid-template-columns: 1fr;
            gap: 10px;
        }
    }

    .form__wrapper {
        padding: 15px;
    }
}