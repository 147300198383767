$header-bar-color: #e0e0e020;
$primary-bg-color: #E0E0E0;
$alternate-bg-color: #ffffff;
$primary-text-color: #3C4A6B;
$alternate-text-color: #333333;
$cta-color: #FF6F61;
$border-color: #a89e9e;
$transition: all 0.2s ease-in-out;
$error: #af1313;

/* Colors specific to files */
$yellow: #FFD54F;
$forest: #388E3C;
$coral: #FF6F61;
$lime : #CDDC39;
$file-text-color: #ffffff;