@import '../sass/variables';

.accordions {

  border-bottom: 1px solid $border-color;
  margin-top: 10px;

  .accordion-item {

    .accordion-title {
      cursor: pointer;
      margin: 0;
      padding: 0px 10px 0;
      border: none;
      border-top: 1px solid $border-color;
      display: flex;
      justify-content: space-between;
      justify-items: center;
      align-items: center;

      h3, h5, span {
        transition: $transition;
      }

      h3,
      span {
        margin-top: 15px;
      }

      h5,
      span {
        margin-bottom: 15px;
      }

      h3 {
        margin-bottom: 0;
      }

      h5 {
        margin-top: 0;
      }
    }

    .accordion-title:hover h3,
    .accordion-title:hover h5,
    .accordion-title:hover span {
      color: $cta-color;
    }

    .accordion-title:hover span {
      transform: scale(1.5);
    }

    .accordion-content {
      margin: 0px;
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 1.1rem;

      &.active {
        max-height: 500px; /* Adjust based on content size */
        padding-top: 0px;
        padding-bottom: 15px;
      }
    }
  }
}

.job {
  margin-bottom: 15px;

  &__description,
  &__list {
    margin: 0;
    padding: 0;
  }

  &__list {

    list-style: square;

    li {
      margin-left: 15px;
    }
  }
}

@media only screen and (max-width: 650px) {
  .accordions .accordion-item .accordion-content {
    font-size: 1rem;
  }
}