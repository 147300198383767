@import '../../sass/mixins';
@import '../../sass/variables';

.article {
    @include page-layout;

    &__group {
        display: grid;
        grid-template-columns: 1fr 1.5fr;
        gap: 60px;
        margin-top: 30px;
        margin-bottom: 40px;
        height: 700px;
    }

    &__description {
        p {
            margin: 0;
            margin-bottom: 20px;
        }
    }

    &__media {
        overflow: scroll;
        border-radius: 7.5px;
        margin-bottom: 20px;

        p {
            font-size: 1rem;
        }
    }

    &__image, &__video {
        width: 100%;
        object-fit: contain;
        max-height: 450px;
        border-radius: 7.5px;
    }

    &__image.mobile {
        max-height: 300px;
    }

    &__image-description {
        margin: 0;
        margin-bottom: 40px;
        text-align: center;
    }
}


@media only screen and (max-width: 1000px) {
    .article {
        &__group {
            grid-template-columns: 1fr;
            gap: 20px;
            height: auto;
        }
    }
}

@media only screen and (max-width: 650px) {
    .article {
        &__media p {
            font-size: 0.85rem;
        }
    }
}

@media only screen and (max-width: 500px) {
    .article {
        &__image.mobile {
            max-height: 200px;
        }
    }
}
