@import '../sass/variables';

.social {
    margin-top: 20px;
    display: flex;
    gap: 20px;

    &__image-wrapper {
        width: 60px; 
        height: 60px; 
        overflow: hidden;
        border-radius: 50%; 
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.3;
        transition: $transition;

        &:hover {
            cursor: pointer;
            opacity: 0.5;
        }
    }
    
    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
    }   
}
