@import '../sass/mixins';
@import '../sass/variables';

.about {
    text-align: left;
    padding: 80px 50px 50px;

    &__title {
        font-weight: 900;
        font-size: 4rem;
        font-family: 'Georgia', 'Times New Roman';
        letter-spacing: 3px;
        text-transform: uppercase;
        margin: 0;
    }

    &__divider {
        border-bottom: 1px double $border-color;
    }

    &__group {
        @include grid(40px, 2, 850px);
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }

    &__description {
        font-size: 1.2rem;
        line-height: 1.6;
        width: 85%;

        h1 {
            margin: 0;
        }
    }

    &__image-wrapper {
        @include responsive-image;
        width: 100%;
    }

    &__image {
        object-fit: cover;
        filter: brightness(0.95);
        height: 500px;
        border-radius: 7.5px;
    }
}

@media only screen and (max-width: 1000px) {
    .about {

        &__title {
            font-size: 3.25rem;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .about {

        &__description {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 650px) {
    .about {
        padding: 50px 20px;

        &__title {
            font-size: 2.5rem;
        }

        &__description {
            font-size: 1rem;
        }
    }
}