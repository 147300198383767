@import '../sass/mixins';
@import '../sass/variables';

.services {
    @include section-layout(50px, 50px 20px);
    @include section-title;

    &__group {
        display: flex;
        justify-content: space-between;
        gap: 30px;
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }
}

@media only screen and (max-width: 1000px) {
    .services__group {
        @include grid(30px, 2, 500px);
    }
}

