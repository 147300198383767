.content__wrapper {
    display: grid;
    place-items: center;  

    .content__container {
        width: 100%;
        max-width: 1280px;
        justify-content: center;
        align-items: center;
    }
}
