@import '../sass/mixins';
@import '../sass/variables';

.footer {
    @include section-layout;

    &__content {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        margin-top: 40px;
    }

    &__menu {
        list-style: none;
        padding-inline-start: 0;

        a {
            text-decoration: none;
        }
    }

    &__menu-item:hover {
        color: $cta-color;
        cursor: pointer;
    }

    &__menu, &__contact-detail {
        margin: 0;
        color: $primary-text-color;
    }

    &__copyright {
        margin-top: 40px;
        font-size: 0.85rem;
    }

}
