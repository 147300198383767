@import '../sass/mixins';
@import '../sass/variables';

.error {
  margin: 15vh 100px;
  
  &__button {
    @include default-button;
  }
}

