.tags {
  display: flex;
  flex-wrap: wrap; 
  gap: 7.5px; 
  padding: 0;
  list-style-type: none;

  li {
    background: rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
    border-radius: 5px;
  }
}