@import '../sass/variables';

.hero {
    background-color: $alternate-bg-color;
    background-image: url('../assets/images/office.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;
    font-size: 2.75rem;
    padding: 50px;
    padding-top: 70px;
    margin-top: -110px;

    &__content {
        text-align: left;
        width: 60%;

        &-title {

            span {
                font-size: 4.5rem;
                font-weight: 900;
                color: $alternate-text-color;
            }
        }
    }
}


@media only screen and (max-width: 1000px) {
    .hero {
        font-size: 2.0rem;
        height: 425px;

        &__content {
            width: 65%;

            &-title span {
                font-size: 3.5rem;
            }
        }
    }
}

@media only screen and (max-width: 650px) {
    .hero {
        font-size: 1.75rem;
        height: 350px;
        padding-left: 20px;

        &__content {

            width: 70%;

            &-title span {
                font-size: 2.5rem;
            }
        }

    }
}
