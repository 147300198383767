@mixin default-button() {
    & {
        margin-top: 15px;
        background-color: $primary-text-color;
        color: $primary-bg-color;
        border: none;
        padding: 10px 30px;
        border-radius: 5px;
        transition: $transition;
        font-family: 'Georgia', 'Times New Roman';
    
        &:hover {
          background-color: $cta-color;
          cursor: pointer;
        }
    }
}

@mixin responsive-image($border-radius: 7.5px, $object-fit: contain) {
    & {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: 100%;
        border-radius: $border-radius;

        &__image {
            width: 100%;
            height: auto;
            object-fit: $object-fit;
        }
    }
}


@mixin page-layout($margin-bottom: 20px) {
    & { 
        text-align: left;
        font-size: 1.1rem;
        line-height: 1.6;
        padding: 0px 40px 0px;

        @media only screen and (max-width: 650px) {
            font-size: 1rem;
            padding: 0px 20px 0px;
        }

        &__content {
            background-color: $alternate-bg-color;
            padding: 5px 20px $margin-bottom;
        }
    }
}


@mixin section-layout($padding: 20px 50px, $padding-mobile: 20px) {
    & {
        text-align: left;
        font-size: 1.2rem;
        line-height: 1.6;
        padding: $padding;

        @media only screen and (max-width: 650px) {
            font-size: 1rem;
            padding: $padding-mobile;
        }
    }
}

@mixin section-title($margin: 0, $size: 3rem, $size-mobile: 2rem) {
    & {
        &__title {
            margin: $margin;
            font-size: $size;

            @media only screen and (max-width: 650px) {
                font-size: $size-mobile;
            }
        }
    }
}

@mixin grid($gap, $columns, $screen-width: 650px) {
    & {
        display: grid;
        grid-template-columns: repeat($columns, 1fr);
        gap: $gap;

        @media (max-width: $screen-width) {
            grid-template-columns: 1fr; 
        }
    }
}

@mixin file-variant($color, $z-index, $degree, $left, $top) {
    & {
        background-color: $color;
        z-index: $z-index;
        transform: rotate($degree);
        left: $left;
        top: $top;
    }
}

@mixin file-variant-mobile($left, $top, $degree, $new-top, $new-degree) {
    & {
        left: $left;
        top: $top;
        transform: rotate($degree);

        &:hover {
            top: $new-top;
            transform: rotate($new-degree);
        }
    }
}
